// PlayToTV Server etc.
import { triggerLogout } from 'actions/userActions';
import { getPttvDispatch, getPttvState, getPttvSub } from 'connectors/connectPttv';
import { ThunkDispatch } from 'hooks/redux';
import { Unsubscribe } from 'redux';
import { getLoginState, getPttvSession, getSessionValidationState } from 'selectors/userSelectors';
import 'vendor/playtotv';

function waitForPlayToTV() {
  return new Promise<void>((resolve) => {
    const readyCheck = () => {
      if (window.playtotv.server && window.playtotv.server.Server) {
        return resolve();
      }

      setTimeout(readyCheck, 1);
    };

    readyCheck();
  });
}

const serverFromQuery = () => {
  if (__TEST__) {
    return false;
  }

  const { search } = window.location;
  const match = search.match(/[?|&]server=([^&]*)+/);

  if (!match) {
    return false;
  }

  const [, server] = match;

  return server;
};

// Lazy Server instantiation
let server: PlayToTVServerInterface | null = null;

export const getServer = async () => {
  await waitForPlayToTV();

  if (!server) {
    const url = serverFromQuery() || `https://${window.emconfig.backendHost}`;

    server = new window.playtotv.server.Server({ url });
  }

  return server;
};

export const getServerSync = () => {
  if (!server) {
    throw new Error('Server not initialized');
  }

  return server;
};

interface RequestError {
  statusCode: number;
  type: 'NOT_FOUND'; // TODO Extend me
  message: string;
  shouldLog: boolean;
}

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

function handleRequestError(
  httpMethod: HttpMethod,
  error: Error,
  requestError: RequestError,
  url: string,
  API: string,
  requestSession: string,
) {
  switch (requestError.statusCode) {
    case 401:
    case 463: {
      const appState = getPttvState();
      // Check the session to make sure the error is on the correct session id
      const currentSession = getPttvSession(appState);
      if (currentSession && requestSession !== currentSession) return;

      // Account is already logged in to another device.
      const loginState = getLoginState(appState);

      if (loginState !== 'LOGGING_OUT' && loginState !== 'LOGGED_OUT') {
        const dispatch = getPttvDispatch() as ThunkDispatch;
        dispatch(triggerLogout());
      }
      return;
    }
    case 404:
      return;
  }
}

export interface RequestOptions {
  needsValidSession?: boolean;
  useSessionCookie?: boolean;
}

async function request<T = any>(
  type: HttpMethod,
  path: string,
  requestData: any = {},
  options?: RequestOptions,
): Promise<T> {
  const server = await getServer();
  let response: T;

  const buildUrl = () => {
    const requestSession = getPttvSession(getPttvState()) || '';
    const glueChar = path.indexOf('?') === -1 ? '?' : '&';
    const sessionQueryParam = options?.useSessionCookie
      ? ''
      : `${glueChar}session=${requestSession}`;

    return {
      url: `1/${path}${sessionQueryParam}`,
      requestSession,
    };
  };

  const sessionValidation = getSessionValidationState(getPttvState());
  let url = buildUrl().url;
  let requestSession = buildUrl().requestSession;

  try {
    if (options?.needsValidSession && sessionValidation !== 'VALIDATED') {
      let unsubscribe: Unsubscribe | undefined;

      await new Promise((resolve) => {
        unsubscribe = getPttvSub((getState) => {
          const appState = getState();
          if (getSessionValidationState(appState) === 'VALIDATED') {
            url = buildUrl().url;
            requestSession = buildUrl().requestSession;
            resolve(true);
          }
        });
      });

      if (unsubscribe) unsubscribe();
    }

    response = await server.call<T>(
      {
        label: `${type} ${path}`,
        type,
        url,
      },
      {},
      {
        data: requestData,
      },
    );
  } catch (error: any) {
    if (error.data && error.data.error) {
      handleRequestError(type, error, error.data.error, url, server.urls.API, requestSession);
    }
    throw error;
  }

  return response;
}

export type Api = {
  get<T = any>(url: string, skipValidation?: boolean): Promise<T>;
  post<T = any, D = any>(url: string, data?: D, useSessionCookie?: boolean): Promise<T>;
  put<T = any, D = any>(url: string, data?: D, useSessionCookie?: boolean): Promise<T>;
  delete<T = any>(url: string, useSessionCookie?: boolean): Promise<T>;
};

export const api: Api = {
  get(url, skipValidation) {
    return request('GET', url, undefined, { needsValidSession: !skipValidation });
  },
  post(url, data, useSessionCookie) {
    return request('POST', url, data, { useSessionCookie });
  },
  put(url, data, useSessionCookie) {
    return request('PUT', url, data, { useSessionCookie });
  },
  delete(url, useSessionCookie) {
    return request('DELETE', url, undefined, { useSessionCookie });
  },
};
