import { UserReducer } from 'reducers/user';
import { isFacebookUser, isGoogleUser } from 'selectors/socialSelectors';
import { QzmProfile } from 'types/api/userApi';
import { Profile } from 'types/user';
import { signOutFacebook } from './bridgeActions';
import { createAction, createActionWithPayload, ThunkAction } from './helpers';
import { push } from './historyActions';
import { RegisterWithEmailData } from './interUserApiActions';
import { removeStoredAuthenticationToken } from './nativeActions';
import { googleSignOut } from './socialActions';
import { logout } from './usersApiActions';

export const joinEpisode = (): ThunkAction<Promise<void>> => async (dispatch, getState) => {
  const { episode } = getState();

  if (!episode) {
    return;
  }

  const { episodeCode } = episode;

  dispatch(setEpisodeJoined(episodeCode));
};

/**
 * Trigger the logout flow for the user. Always use this instead of calling logout() directly.
 */
export const triggerLogout = (): ThunkAction<Promise<void>> => async (dispatch, getState) => {
  const appState = getState();

  dispatch(setLoginState('LOGGING_OUT'));
  dispatch(removeStoredAuthenticationToken());

  try {
    if (isFacebookUser(appState)) {
      await dispatch(signOutFacebook());
    }

    if (isGoogleUser(appState)) {
      await dispatch(googleSignOut());
    }

    await dispatch(logout());
  } catch {}

  dispatch(clearUser());
  dispatch(push('/'));

  setTimeout(() => {
    window.location.reload();
  }, 250);
};

export const updateRegistrationProfile = (payload: RegisterWithEmailData) =>
  createActionWithPayload('@user/UPDATE_REGISTRATION_PROFILE', payload);

export const setEpisodeJoined = (episodeCode: string) =>
  createActionWithPayload('@user/EPISODE_JOINED', episodeCode);

export const storeUser = (user: Partial<UserReducer>) =>
  createActionWithPayload('@user/STORE_USER', user);

export const storeUserProfile = (profile: Profile) =>
  createActionWithPayload('@user/STORE_USER_PROFILE', profile);

export const clearUser = () => createAction('@user/CLEAR_USER');

export const setProfileStatistics = (profileStatistics: QzmProfile) =>
  createActionWithPayload('@user/STORE_USER_PROFILE_STATISTICS', profileStatistics);

export const setSessionValidationState = (state: UserReducer['sessionValidationState']) =>
  createActionWithPayload('@user/SET_VALIDATION_STATE', state);

export const storePasswordResetToken = (passwordResetToken: string | undefined) =>
  createActionWithPayload('@user/STORE_PASSWORD_RESET_TOKEN', passwordResetToken);

export const setLoginState = (loginState: UserReducer['loginState']) =>
  createActionWithPayload('@user/SET_LOGIN_STATE', loginState);

export const updateEmailFieldStorage = (payload?: string) =>
  createActionWithPayload('@user/UPDATE_EMAIL_FIELD_STORAGE', payload);

export type UserActions = ReturnType<
  | typeof updateRegistrationProfile
  | typeof storeUser
  | typeof setSessionValidationState
  | typeof clearUser
  | typeof storeUserProfile
  | typeof setProfileStatistics
  | typeof storePasswordResetToken
  | typeof setEpisodeJoined
  | typeof setLoginState
  | typeof updateEmailFieldStorage
>;
